import React from 'react'
import HeadNav from './HeadNav'
import FootNav from './FootNav'

interface LayoutProps {
  location: object
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => (
  <div>
    <HeadNav location={props.location}/>
      {props.children}
    <FootNav location={props.location}/>
  </div>
)

export default Layout

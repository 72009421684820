import React from 'react'
import { Link } from 'gatsby'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'
import { withSessionContext, SessionContext } from 'clouden-components/context/session'
import { AccountSwitcher } from 'clouden-components/views/account-switcher'
import cloudenLogo from '../images/clouden-2018-logo-128-inverse.png'
import { Trans } from 'react-i18next'

interface HeadNavProps {
  location: any
  sessionContext: SessionContext
}

class HeadNav extends React.Component<HeadNavProps> {
  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleSignOut = (event: any) => {
    event.preventDefault()
    this.props.sessionContext.signOut()
  }

  render() {
    const pathname = (this.props.location && this.props.location.pathname) || null
    return (
      <Navbar expand="md p-3">
        <div className="container">
          <Link className="navbar-brand" to="/"><img src={cloudenLogo} alt="Clouden Ping"/></Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem active={pathname === '/dashboard/'}>
                <Link className="nav-link" to="/dashboard/"><span className="fa fa-home"></span>{' '}<Trans i18nKey="header_dashboard">Dashboard</Trans></Link>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <AccountSwitcher/>
              {this.props.sessionContext.state !== 'signedIn' ?
              <NavItem active={pathname === '/dashboard/'}>
                <Link className="nav-link" to="/dashboard/"><span className="fa fa-sign-in"></span> <Trans i18nKey="header_sign_in">Sign in</Trans></Link>
              </NavItem> : null}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    )
  }
}

export default withSessionContext(HeadNav)

import React from 'react'
import { Link } from 'gatsby'
import config from 'react-global-configuration'
import { Trans } from 'react-i18next'

interface FootNavProps {
  location: any
}

class FootNav extends React.Component<FootNavProps> {
  state = {
    cookiesAccepted: typeof localStorage !== 'undefined' && localStorage.getItem('acceptCookies') === 'true'
  }
  handleAcceptCookies = (event: any) => {
    event.preventDefault()
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('acceptCookies', 'true')
      this.setState({
        cookiesAccepted: true,
      })
    }
  }

  render() {
    return (
      <footer className="footer p-3">
        <div className="container">
          <a className="footer-link" target="_blank" rel="noopener" href={config.get('PING_UI_URL')}><Trans i18nKey="footer_clouden_ping">Clouden Ping</Trans></a>
          {' '}&copy; <a className="footer-link" target="_blank" rel="noopener" href={"https://clouden.net"}>Clouden</a> 2019
          {' '}&nbsp;|&nbsp;{' '} <a className="footer-link" target="_blank" rel="noopener" href={config.get('CLOUDEN_WEB_URL') + "/legal/terms-of-service/"}><Trans i18nKey="footer_terms_of_service">Terms of Service</Trans></a>
          {' '}&nbsp;|&nbsp;{' '} <a className="footer-link" target="_blank" rel="noopener" href={config.get('CLOUDEN_WEB_URL') + "/legal/privacy-policy/"}><Trans i18nKey="footer_privacy_policy">Privacy Policy</Trans></a>
          {' '}&nbsp;|&nbsp;{' '} <Link className="footer-link" to={"/support/"}><Trans i18nKey="footer_support_feedback">Support &amp; Feedback</Trans></Link>
          {this.state.cookiesAccepted ? null : <span>{' '}&nbsp;|&nbsp;{' '} <Trans i18nKey="footer_cookies_required">Use requires allowing cookies</Trans>{' '}<a href="#" onClick={this.handleAcceptCookies}><Trans i18nKey="footer_cookies_hide">HIDE</Trans></a></span>}
        </div>
      </footer>
    )
  }
}

export default FootNav
